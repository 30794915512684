import React from "react";
import "./style.scss";

export default function SectionHeader(props) {
  const { title, subTitle } = props;

  return (
    <div className="section-header">
      <h2>{title}</h2>
      <p>{subTitle}</p>
    </div>
  );
}
