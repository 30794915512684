import React, { useEffect, useState } from "react";
import Header from "../../components/shared/Header/Header";
import PropertyBanner from "../../components/shared/Banner/PropertyBanner";

import { Link, useParams } from "react-router-dom";
import { FaMapMarkerAlt, FaPhoneAlt, FaBuilding, FaCarSide, FaBasketballBall, FaCheck } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BASE_URL, requestApi } from "../../services/api.config";

export default function PropertyDetails() {
  const [propertyDetails, setPropertyDetails] = useState();
  const { slug } = useParams();
  const fetchParticularPropertyDetails = async () => {
    const propertyDetailsReq = {
      url: `${BASE_URL}/property/${slug}`,
      method: "GET",
      data: "",
    };
    const resData_property = await requestApi(propertyDetailsReq);
    if (resData_property.status === true) {
      setPropertyDetails(resData_property?.results);
    } else {
      //  console.log('-- messge',resData_property.message)
    }
  };
  useEffect(() => {
    fetchParticularPropertyDetails();
  }, []);

  const handleChange = (e) => {};

  return (
    <>
      {/* Header */}
      <Header />

      {/* Project Banner */}
      <PropertyBanner data={propertyDetails?.propertyName} />

      <section className="project-details px-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="property-header">
                <div>{propertyDetails?.type}</div>
                <h1>{propertyDetails?.propertyName}</h1>

                <div className="d-flex align-items-center">
                  <FaMapMarkerAlt />
                  <span className="ms-1">{propertyDetails?.location}</span>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="share-property"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="py-4">
                <img src={propertyDetails?.displayImage} className="img-fluid" alt="" />
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-8">
              <div className="left-widget">
                <div className="property-content">
                  <h3 className="title">Overview</h3>
                  <div className="row">
                    {propertyDetails?.overview.map((overview, index) => (
                      <div className="col-md-3" key={index++}>
                        <div className="overview-content">
                          <h3>{overview.title}</h3>
                          <div className="overview-icon">
                            {/* {overview?.icon}*/} <span>{overview.value}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="property-content">
                  {/* <h3 className="title">About This Listing</h3> */}
                  {/* <img src={propertyDetails?.displayImage} className="img-fluid about-image" /> */}
                  {/* <h2 className="about-main-title">{propertyDetails?.propertyName}</h2> */}
                  <div dangerouslySetInnerHTML={{ __html: propertyDetails?.aboutContent }} />
                  {/* <h5 className="about-title">Luxurious 1, 2, and 3-bedroom apartments, townhouses and 4-5 Bedroom Lavish Penthouses.</h5>
                <h6 className="about-intro-title">INTRODUCING THE NEW JEWEL OF THE VALLEY</h6>
                <p>
                  Damac Properties unveils its latest masterpiece, The Sapphire at Sheikh Zayed Road, a high-rise tower sets to redefine luxury living with luxury class apartments in Dubai. Urban
                  living comes alive at The Sapphire, a collection of luxury apartments, townhouses, and ultra-luxury penthouses. Standing bright against the Dubai skyline, The Sapphire offers
                  elevated luxury living minutes from everything the city has to offer. The luxury residence is branded by renowned jewellery, de GRISOGONO. Drawing inspiration from the yellow
                  sapphire, each element sets the tone for timeless elegance, bringing to life a vision of opulent living. The Sapphire is a landmark of luxury that flourishes with unique amenities,
                  from a canary garden on the rooftop to an infinity pool that transitions into a mesmerizing light spectacle in the evening. The rooftop also features a sunflower sanctuary that
                  serves as a tranquil co-working space that inspires creativity and innovation.
                </p>
                <p>Welcome to The Sapphire, where exceptional living comes to light.</p>
              </div>

              <div className="property-content">
                <h6 className="title">Key Highlights</h6>
                <p>Boasting a 54 floors, including 1, 2, and 3-bedroom apartments, townhouses, and lavish penthouses.</p>
                <p>Residents will be greeted by a branded entrance area and elegantly designed ground-level lobby, setting the tone for the luxurious living experience.</p>
                <p>
                  The podium levels feature townhouses, while the fifth floor houses a luxury spa retreat and a 24/7 gymnasium for residents’ indulgence and relaxation. The 53rd and 54th floors
                  feature exclusive penthouses, epitomizing luxury and sophistication.
                </p>
                <p>Residents can enjoy a host of extraordinary amenities, including sunflower and canary gardens, Circadian Pods, and an observation deck.</p>
                <p>Unique features like the immersive ring of light, energy bubble, and immersive nature elements add a touch of magic to the living experience.</p>
                <p>The rooftop amenities, including a roof garden and swimming pool, provide residents with moments of leisure and relaxation amidst the urban landscape.</p> */}
                </div>

                <div className="property-content">
                  <h6 className="title">Amenities</h6>
                  <p>
                    <strong>Enchantingly Green, Undeniably Serene </strong>
                  </p>
                  <p>
                    At Park Greens, every day brings fresh adventures, captivating explorations, and peaceful moments of serenity. Relish the excitement of riding the waves, drift along the lazy
                    river, and embrace memorable experiences. Experience endless hours of water-friendly fun in the sun with water attractions such as the massive Malibu Beach, a wave pool, a splash
                    pad, slippery slides and much more.
                  </p>
                  <ul className="row">
                    {propertyDetails?.featuresAmenities.map((featuresAmenities, index) => (
                      <li className="col-md-4 d-flex align-items-center my-2" key={index++}>
                        <span className="ameniti-list-circle">
                          <FaCheck />
                        </span>
                        <span>{featuresAmenities?.name}</span>
                      </li>
                    ))}
                    {/* <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Infinity Pool</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Luxury SPA Retreat</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>24/7 GYM</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>F&B and Retail Outlets</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Sunflower Sanctuaries</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Canary Gardens</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Recreation Areas</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Yoga facilities</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Co-working Spaces</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Hospitals and specialty clinics</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Community schools</span>
                  </li>
                  <li className="col-md-4 d-flex align-items-center my-2">
                    <span className="ameniti-list-circle">
                      <FaCheck />
                    </span>
                    <span>Podium Riviera</span>
                  </li> */}
                  </ul>
                </div>

                <div className="property-content">
                  <h6 className="title">Amenities</h6>
                  <p>
                    <strong>Enchantingly Green, Undeniably Serene </strong>
                  </p>
                  <p>
                    At Park Greens, every day brings fresh adventures, captivating explorations, and peaceful moments of serenity. Relish the excitement of riding the waves, drift along the lazy
                    river, and embrace memorable experiences. Experience endless hours of water-friendly fun in the sun with water attractions such as the massive Malibu Beach, a wave pool, a splash
                    pad, slippery slides and much more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="property-sidebar">
                <div className="property-contact mb-4">
                  <h3>Contact Us</h3>
                  <form onSubmit={(e) => {}}>
                    <div className="mb-3">
                      <input type="text" className="form-control" placeholder="Enter Your Name" name="name" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="mb-3">
                      <input type="text" className="form-control" placeholder="Enter email" name="email" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="mb-3">
                      <input type="text" className="form-control" placeholder="Phone" name="phone" onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="mb-3">
                      <textarea className="form-control" placeholder="Message..." name="comment" onChange={(e) => handleChange(e)}></textarea>
                    </div>
                    <div className="d-grid gap-2">
                      <button className="btn btn-primary" type="submit">
                        Send Message
                      </button>
                    </div>
                  </form>
                </div>

                <div className="property-contact">
                  <div className="d-flex mb-3">
                    <div className="contact-icons">
                      <FaMapMarkerAlt size={20} />
                    </div>
                    <div className="contact-info">19th floor , Office No-31, The Binary Tower , Business Bay, Dubai, UAE</div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="contact-icons">
                      <MdEmail size={20} />
                    </div>
                    <div className="contact-info">info@karimi.ae</div>
                  </div>

                  <div className="d-flex mb-3">
                    <div className="contact-icons">
                      <FaPhoneAlt size={18} />
                    </div>
                    <div className="contact-info">Karimi Real Estate:+971544101973</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
