import React, { useEffect, useState } from "react";
import Header from "../../components/shared/Header/Header";
import ProjectBanner from "../../components/shared/Banner/ProjectBanner";
import Footer from "../../components/shared/Footer/Footer";
import { projects } from "../../data/project";
import PropertyList from "../../components/shared/PropertyList/PropertyList";
import SectionHeader from "../../components/shared/SectionHeader/SectionHeader";
import { BASE_URL, requestApi } from "../../services/api.config";

export default function Projects() {
  const [listProperty, setListProperty] = useState([]);
  const fetchListProperty = async () => {
    const listPropertyReq = {
      url: `${BASE_URL}/properties?page=1&limit=4`,
      method: "GET",
      data: "",
    };
    const resData_listproperty = await requestApi(listPropertyReq);
    if (resData_listproperty?.results?.data?.length > 0) {
      setListProperty(resData_listproperty?.results?.data);
    } else {
      //  console.log('-- messge',resData_property.message)
    }
  };
  useEffect(() => {
    fetchListProperty();
  }, []);
  return (
    <>
      {/* Header */}
      <Header />

      {/* Project Banner */}
      <ProjectBanner />

      {/* Projects */}
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <SectionHeader title="FEATURED PROJECTS" subTitle="Best Properties in Dubai" />
            </div>
          </div>
          <div className="row">
            {listProperty.map((item, index) => (
              <div className="col-md-4" key={index}>
                <PropertyList data={item}  type="property" />
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </>
  );
}
