import React, { useEffect, useState } from "react";
import Header from "../../components/shared/Header/Header";
import ProjectBanner from "../../components/shared/Banner/ProjectBanner";
import Footer from "../../components/shared/Footer/Footer";
import { neighborhood } from "../../data/home";
import SectionHeader from "../../components/shared/SectionHeader/SectionHeader";
import LocationList from "../../components/shared/LocationList/LocationList";
import { BASE_URL, requestApi } from "../../services/api.config";

export default function Locations() {
  const [locationData,setLocationData] = useState([]);
  const fetchDevelopData = async () =>{
    const homeReq  = {
      url : `${BASE_URL}/page/home`,
      method : "GET",
      data :"",
    };
    const resData_Location = await requestApi(homeReq);
    if(resData_Location.status === true){
      setLocationData(resData_Location?.results?.developers);
    }else{
    //  console.log('Fetching Developer List')
    }
  }
  useEffect(()=>{
    fetchDevelopData();
  },[])
  return (
    <>
      {/* Header */}
      <Header />

      {/* Project Banner */}
      <ProjectBanner />

      <div className="locations__page">
        <section className="py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <SectionHeader title="Explore Properties in Dubai's" subTitle="Top Communities Properties" />
              </div>
            </div>
            <div className="row">
              {locationData.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <LocationList data={item} />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
