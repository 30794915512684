import React from "react";
import "./style.scss";

export default function ProjectBanner() {
  return (
    <div className="project-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h2>Discover Your Ideal Property with Our Expert Assistance</h2>
              <p>Your ideal home is just a message away. Contact us today.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
