import React from "react";
import { Routes, Route } from "react-router-dom";

// Pages
import Home from "../pages/website/Home";
import Contact from "../pages/website/Contact";
import Projects from "../pages/website/Projects";
import Locations from "../pages/website/Locations";
import Developers from "../pages/website/Developers";
import AboutUS from "../pages/website/About";
import Blog from "../pages/website/Blog";
import PropertyDetails from "../pages/website/PropertyDetails";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="contact" element={<Contact />} />
      <Route path="projects-dubai" element={<Projects />} />
      <Route path="locations-dubai" element={<Locations />} />
      <Route path="/developers/:slug" element={<Developers />} />
      <Route path="/property/:slug" element={<PropertyDetails />} />
      <Route path="about" element={<AboutUS />} />
      <Route path="blog" element={<Blog />} />
    </Routes>
  );
}
