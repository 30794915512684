import React, { useState } from "react";
import "./style.scss";
import { BASE_URL, requestApi } from "../../../services/api.config";
import Swal from "sweetalert2";

export default function ChatWidget(props) {
  const [showChat, setShowChat] = useState(false);
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const successAlert = () => {
    Swal.fire({
      title: "Thank You !!!",
      text: "Your inquiry completed successfully",
      icon: "success",
      allowOutsideClick: false,
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.value) {
        window.location.reload();
      } else {
      }
    });
  };

  const errorAlert = () => {
    Swal.fire({
      title: "Oops!!!",
      text: "Something went wrong",
      icon: "error",
      allowOutsideClick: false,
      confirmButtonText: "OK",
    }).then((result) => {
      if (result.value) {
        window.location.reload();
      } else {
      }
    });
  };
  const inquiryFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const reqData = {
        url: `${BASE_URL}/inquiry`,
        method: "POST",
        data: {
          userName: formData.name,
          email: formData.email,
          country: "Dubai",
          phone: formData.phone,
          message: formData.comment,
        },
      };
      const resData = await requestApi(reqData);
      setFormData({});
      if (resData.status == true) {
        // alert("Thankyou for inquiry..")
        // window.location.href = "/";
        successAlert();
      } else {
        // alert(resData.message);
        errorAlert();
      }
    } catch (error) {
      // console.log("---- error---", error);
      // alert("something went wrong !!!");
      errorAlert();
    }
  };

  return (
    <div className="chat-widget">
      <div className={`widget-form ${showChat ? "active" : ""}`}>
        <h3>Hi there 👋</h3>
        <p>Need help in Property search? Start a conversation:</p>
        <form onSubmit={(e) => inquiryFormSubmit(e)}>
          <div className="mb-2">
            <input type="text" className="form-control" placeholder="Enter Your Name" name="name" onChange={(e) => handleChange(e)} />
          </div>
          <div className="mb-2">
            <input type="text" className="form-control" placeholder="Enter email" name="email" onChange={(e) => handleChange(e)} />
          </div>
          <div className="mb-2">
            <input type="text" className="form-control" placeholder="Phone" name="phone" onChange={(e) => handleChange(e)} />
          </div>
          <div className="mb-3">
            <textarea className="form-control" placeholder="Message..." name="comment" onChange={(e) => handleChange(e)}></textarea>
          </div>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" type="submit">
              Send Message
            </button>
          </div>
        </form>
      </div>

      <div className="user-chat" onClick={() => setShowChat(!showChat)}>
        {!showChat ? <img src="images/meetme.png" width="60" alt="" /> : <img src="images/close-chat.png" width="60" alt="" className="rotate" />}
      </div>
    </div>
  );
}
