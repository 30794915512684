import axios from "axios";

// ### Development Environment
// const BASE_URL = "http://localhost:8000/api/v1";
// ### Live Environment
const BASE_URL = "https://api.karimi.ae/v1";
const USER_ID = "karimi";
const USER_PASSWORD = "karimi@123";
const requestApi = async (request) => {
  try {
    const response = await axios({
      url: request.url,
      method: request.method,
      data: request.data,
      headers: {
        Accept: "application/json",
        "x-application-id": USER_ID,
        "x-application-name": USER_PASSWORD,
      },
    });
    return response.data;
  } catch (error) {
    console.log("API Error", error);
    return error;
  }
};

export { BASE_URL, requestApi };
