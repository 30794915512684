import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";

export default function LocationList(props) {
  const { data } = props;

  return (
    <div className="location-box">
      <Link to={`/developers/${data.agent._id}`}>
        <div className="content">
          <h4>{data.agent.userName}</h4>
          <span>{data.photo.length} Properties</span>
        </div>
        <div className="thumb-img" style={{ backgroundImage: `url(https://api.karimi.ae/images/${data.photo[0].url})` }}>
          <div className="overlay"></div>
        </div>
      </Link>
    </div>
  );
}
