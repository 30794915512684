import React, { useEffect, useState } from "react";
import { BASE_URL, requestApi } from "../../../services/api.config";

export default function SearchProperty() {
  const [locationList,setLocationList] = useState([]);
  const[deveLoperList,setDeveloperList]=useState([])
  // location api hit function
  const fetchLocationList = async () =>{
    const reqLocation  = {
      url : `${BASE_URL}/locations`,
      method : "GET",
      data :"",
    };

    const resData = await requestApi(reqLocation);
    if(resData.status === true){
      setLocationList(resData.results);
    }else{
     console.log('Fetching Location')
    }
  }
  const fetchDeveloperList = async () =>{
    const developerList  = {
      url : `${BASE_URL}/developers`,
      method : "GET",
      data :"",
    };

    const resData_deveploper = await requestApi(developerList);
    if(resData_deveploper.status === true){
      setDeveloperList(resData_deveploper.results);
    }else{
     console.log('Fetching Developer List')
    }
  }
  useEffect(()=>{
    fetchLocationList();
    fetchDeveloperList();
  },[])
  return (
    <section className="search-property">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="search-box d-md-flex">
              <div>
                <input type="text" placeholder="Enter Keyword here..." className="form-control shadow-none" />
              </div>
              <div>
                <select className="form-select shadow-none">
                  <option value={"_cc"}>Select Category</option>
               {deveLoperList?.map((data,index)=>( <option key={index++} value={data._id}>{data.userName}</option>))}
                </select>
              </div>
              <div>
                <select className="form-select shadow-none">
                  <option>Select Type</option>
                  <option>Apartments</option>
                  <option>Commercial</option>
                  <option>Townsquare</option>
                </select>
              </div>
              <div>
                <select className="form-select shadow-none">
                  <option value = {"_ll"}>Select Location</option>
                  {locationList?.map((location,i)=>(
                     <option key={i++} value={location._id}>{location.name}</option>
                  ))}
                </select>
              </div>
              <div>
                <button className="btn btn-dark">
                  <span>SEARCH</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
