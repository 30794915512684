export const featuredProperty = [
  {
    name: "ORIAL Launched by Emaar",
    category: "EMAAR",
    location: "Dubai Creek Harbour",
    image: "images/temp/oria.jpeg",
  },
  {
    name: "Nshama Town Squar",
    category: "Nshama",
    location: "Town Square Dubai",
    image: "images/temp/kaya.jpeg",
  },
  {
    name: "SAPPHIRE ",
    category: "DMAC",
    location: "SAPPHIRE launched by Dmac 👆🏻",
    image:
      "images/temp/dmac.jpeg",
  },
  {
    name: "DANUBE BAYZ 101",
    category: "DANUBE PROPERTIES",
    location: "Business bay, Dubai",
    image: "images/temp/AED.jpeg",
  },
  {
    name: "Trillionaire Residences by Binghatti",
    category: "Binghatti",
    location: "Town Square",
    image: "images/temp/bing.png",
  },
  {
    name: "AZIZI VENICE",
    category: "Azizi Development",
    location: "Dubai South",
    image: "images/temp/aziji.jpg",
  },
];

export const buyingProperty = [
  {
    name: "The Acres by Meeras ",
    category: "Meraas Holding",
    location: "Dubai Land",
    image: "images/view-dubai/d_1.png",
  },
  {
    name: "Nad Al Sheba Gardens by Meeras ",
    category: "Meraas Holding",
    location: "Mohammed Bin Rashid City",
    image: "images/view-dubai/d_2.png",
  },
  {
    name: "Haven by Aldar ",
    category: "Aldar",
    location: "Dubai Land",
    image: "images/view-dubai/d3.png",
  },
  {
    name: "COMO Residences by Nakheel on Palm Jumeirah ",
    category: "Nakheel",
    location: "Palm Jumeirah",
    image: "images/view-dubai/4.jpeg",
  },
  {
    name: "The Mayfair by Nshama ",
    category: "Nshama",
    location: "Town Square",
    image: "images/view-dubai/d5.jpg",
  },
  {
    name: "Rixos Dubai Islands Hotel & Residences by Nakheel ",
    category: "Nakheel",
    location: "Dubai Islands",
    image: "images/view-dubai/6.jpeg",
  },
];

export const neighborhood = [
  {
    name: "Arabian Ranches III",
    property: "7 Properties",
    image: "images/neighbour/arabian_ranches.jpeg",
  },
  {
    name: "Dubai Creek Harbour",
    property: "12 Properties",
    image: "images/neighbour/creeckharber.jpg",
  },
  {
    name: "DAMAC Hills",
    property: "11 Properties",
    image: "images/neighbour/damac.jpg",
  },
  {
    name: "Nshama Town Square",
    property: "7 Properties",
    image: "images/neighbour/Nshamna.png",
  },
  {
    name: "All Emaar Buildings",
    property: "6 Properties",
    image: "images/neighbour/emaar.jpg",
  },
  {
    name: "Sobha Hartland",
    property: "4 Properties",
    image: "images/neighbour/sobha.jpg",
  },
];

export const userReview = [
  {
    name: "Raghib Ahmad",
    content:
      "Karimi and their team demonstrated an unparalleled commitment to client satisfaction. What stood out the most was their personalized approach. They took the time to understand my unique needs and preferences, ensuring that every property recommendation was tailored to fit my requirements perfectly.",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjVc1Q3osP71kpbOBPN6DO-EEApKj678ZKdEhWrRpdARYnE=w75-h75-p-rp-mo-br100",
  },
  {
    name: "MR NIHAL",
    content:
      "Their extensive knowledge of the local real estate market was evident throughout the process. Karimi provided valuable insights, guiding me through each step with clarity and expertise. I felt well-informed and confident in my decisions, thanks to their transparent communication and willingness to address any questions I had.",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjUsI3b78zS0CfixeDgflmcHcHUqPLVooXq6Ymod9u1Qe9k=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Md PCE18IT025",
    content:
      "What truly sets Karimi Real Estate Broker apart is their dedication to making the entire experience pleasant and hassle-free. From the initial purchase to the subsequent rental of our new townhouse, their guidance was invaluable. Thanks to their efforts, we've already begun receiving rental income. It's akin to having a trusted friend on the inside working tirelessly for our benefit.",
    image: "https://lh3.googleusercontent.com/a-/ALV-UjWU2Ly_oSuCKpayMgYJy1pLwuilOOPj5gZ7aGtjxG6t3-Y=w75-h75-p-rp-mo-br100",
  },
  {
    name: "Anonymous",
    content:
      "Our first venture into the Dubai real estate market turned out to be a smooth and enjoyable journey, all thanks to Karimi Real Estate Team. Team members , being experienced and professional, made the process incredibly easy for us. He took us to explore various properties, ensuring we had a good range to choose from. Team carefully listened to our preferences and presented options within our budget, easing our concerns as first-time buyers in Dubai. We highly recommend Karimi Real Estate for their genuine care and commitment to their clients. Working with them has been a positive and trustworthy experience.",
    image: "https://images.pexels.com/photos/1055691/pexels-photo-1055691.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
  {
    name: "Timothy Wong Kon Loi",
    content:
      "Working alongside Karmi Team has been a truly enjoyable experience. With his expert guidance and my keen interest in Dubai, I successfully acquired a fantastic apartment from the renowned property developer. I am delighted with this purchase and look forward to more successful ventures in the future.",
    image: "https://images.pexels.com/photos/859265/pexels-photo-859265.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
  },
];

export const blogLists = [
  {
    title: "Maximizing Your Property Investment in Dubai: A Beginner's Guide",
    content:
      "Dubai's real estate market has long been an attractive destination for property investors. Its dynamic environment, futuristic developments, and strategic location make it an ideal place for both local and international investors. If you 're a beginner looking to tap into the potential of Dubai 's property market, this guide will help you navigate the nuances and maximize your investment returns..",
    date: "Feb 17, 2024",
    image: "https://www.shutterstock.com/image-photo/happy-tourist-asian-girl-taking-260nw-2294847207.jpg",
  },
  {
    title: "Navigating the Home Buying Process: Step-by-Step",
    content:
      "Embarking on the journey to purchase your first home can be both exciting and overwhelming. This step-by-step guide will demystify the home buying process, providing you with a clear roadmap to navigate each stage confidently.",
    date: "Feb 03, 2024",
    image: "https://www.shutterstock.com/image-photo/woman-white-hat-standing-on-260nw-1578904942.jpg",
  },
  {
    title: "The Future of Real Estate: Trends to Watch in 2024",
    content:
      "As we approach 2024, the real estate landscape continues to evolve with technological advancements, changing demographics, and global shifts. Here's an in-depth exploration of the trends shaping the future of real estate.",
    date: "Feb 14, 2024",
    image: "https://images.unsplash.com/photo-1688219040240-df6398321243?q=80&w=2049&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    title: "Renovating for Profit: How to Increase Your Property's Value",
    content:
      "Renovating a property for profit requires strategic planning and thoughtful investment. This guide explores key considerations and proven strategies to boost your property's value through renovations.",
    date: "Feb 10, 2024",
    image: "https://www.shutterstock.com/image-photo/dubaiuaejuly32021-new-icon-dubai-museum-260nw-2045063753.jpg",
  },
  {
    title: "Real Estate Investment Strategies for a Changing Market",
    content:
      "As the real estate market undergoes constant transformations, successful investors adapt their strategies to navigate uncertainties. This guide delves into effective real estate investment strategies tailored for a changing market.",
    date: "jan 31, 2024",
    image: "https://www.shutterstock.com/image-photo/arabic-business-man-looking-out-260nw-1700059168.jpg",
  },
  {
    title: "Unlocking the Secrets to Successful Real Estate Investments in Dubai",
    content:
      "Dubai's real estate market is a dynamic landscape filled with opportunities for savvy investors. Whether you're a seasoned pro or a first-time investor, understanding the intricacies of Dubai's property market is key to maximizing your returns.",
    date: "Feb 01, 2024",
    image: "https://www.shutterstock.com/image-photo/united-arab-emirates-dubai-marina-260nw-2171100249.jpg",
  },
];
