import React from "react";
import "./style.scss";

export default function PropertyBanner(props) {
  const {data} = props
  return (
    <div className="property-banner">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="#">DAMCC</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {data}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
