import React, { useEffect, useState } from "react";
import "./style.scss";
import { NavLink } from "react-router-dom";

import { BASE_URL, requestApi } from "../../../services/api.config";

export default function Header() {
  const [deveLoperList, setDeveloperList] = useState([]);

  const fetchDeveloperList = async () => {
    const developerList = {
      url: `${BASE_URL}/developers`,
      method: "GET",
      data: "",
    };

    const resData_deveploper = await requestApi(developerList);
    if (resData_deveploper.status === true) {
      setDeveloperList(resData_deveploper.results);
    } else {
      console.log("Fetching Developer List");
    }
  };

  useEffect(() => {
    fetchDeveloperList();
  }, []);

  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" to="/">
            <img src="../../images/logo.png" width="260" alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" aria-current="page">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/projects-dubai">
                  Projects
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/locations-dubai" className="nav-link">
                  Locations
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="!#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Developers
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  {deveLoperList?.map((data_value, index) => {
                    return (
                      <li key={index++}>
                        <NavLink to={`/developers/${data_value._id}`} className="dropdown-item">
                          {data_value.userName}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              </li>
              <li className="nav-item">
                <NavLink to="/rent" className="nav-link">
                  Rent
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/offPlan" className="nav-link">
                  Off Plan
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/readyToMove" className="nav-link">
                  Ready To Move
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" className="nav-link">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/blog" className="nav-link">
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/contact" className="nav-link">
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
