import React, { useEffect, useState } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import ChatWidget from "../ChatWidget/ChatWidget";

import { FaMapMarkerAlt, FaPhoneAlt, FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { FaEnvelope, FaGlobe, FaLinkedinIn } from "react-icons/fa6";
import { BASE_URL, requestApi } from "../../../services/api.config";

export default function Footer() {
  const [locationList,setLocationList] = useState([]);
  // location api hit function
  const fetchLocationList = async () =>{
    const reqLocation  = {
      url : `${BASE_URL}/locations`,
      method : "GET",
      data :"",
    };

    const resData = await requestApi(reqLocation);
    if(resData.status === true){
      setLocationList(resData.results);
    }else{
      //alert("Something went wrong!!!");
    }
  }
  
  useEffect(()=>{
    fetchLocationList();
 
  },[])
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer-inner">
                <div className="logo">
                  <a className="navbar-brand" to="/">
                    <img src="../images/logo-white.png" width="500" className="img-fluid" alt="" />
                  </a>
                </div>
                <p>You can connect to our social Media Platform to see more update. You can write your comment and query also. </p>
                <div className="social-media">
                  <ul className="d-flex">
                    <li>
                      <a href="https://www.facebook.com/people/Karimi-Realestate/61555704135675/?mibextid=2JQ9oc" target="_blank">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/karimirealestatellc?igsh=MTc3aG5saGFscmVsNQ==" target="_blank">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/karimi_info" target="_blank">
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/in/karimirealestatellc" target="_blank">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="footer-inner">
                <h5>Explore Location</h5>

                <ul className="row">
                {locationList?.map((location,i)=>(
                    <li className="col-md-6" key={i++}>
                     <Link to={`/developers/${location.name}`}>{location.name}</Link>
                   </li>
                  ))}  
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-inner">
                <h5>Contact</h5>

                <div className="d-flex">
                  <div className="me-2">
                    <FaMapMarkerAlt />
                  </div>
                  <div>
                    <p>19th floor , Office No-31, The Binary Tower , Business Bay, Dubai, UAE</p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-2">
                    <FaEnvelope />
                  </div>
                  <div>
                    <p>info@karimi.ae </p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-2">
                    <FaPhoneAlt />
                  </div>
                  <div>
                    <p>Karimi Real Estate:+971544101973</p>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="me-2">
                    <FaGlobe />
                  </div>
                  <div>
                    <p>www.karimi.ae</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* Whats App Chat */}
      <div className="whatsapp-chat">
        <a href="https://api.whatsapp.com/send?phone=+971544101973" target="_blank">
          <img src="images/whatsapp.png" width="50" alt="" />
        </a>
      </div>

      {/* User Chat */}
      <ChatWidget />
    </>
  );
}
