import React from "react";
import CountUp from "react-countup";

export default function RealEstateReports() {
  return (
    <section className="real-estate-report" style={{ background: `url("images/market-overview-bg.jpg")` }}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <p>REAL ESTATE MARKET</p>
              <h2>Dubai Real Estate Market Overview</h2>
              <h4>4th Quarter 2023</h4>
            </div>
          </div>
          <div className="col-md-3">
            <div className="counter-block">
              <div className="number">
                <CountUp start={0} end={35430} enableScrollSpy={true} scrollSpyDelay={100} separator="," suffix=" +">
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
                {/* <CountUp start={0} end={35430} decimal="," suffix="+" enableScrollSpy={true} /> */}
              </div>
              <div className="title">Total Property Sale Transaction</div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="counter-block">
              <div className="number">
                <CountUp start={-10.0} end={122.3} decimals={1} enableScrollSpy={true} scrollSpyDelay={100}>
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
                {/* <CountUp start={-10.0} end={122.3} decimals={1} enableScrollSpy={true} /> */}
              </div>
              <div className="title">Billion AED Sales Value</div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="counter-block">
              <div className="number">
                <CountUp start={-10.0} end={63.4} decimals={1} enableScrollSpy={true} scrollSpyDelay={100}>
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
                {/* <CountUp start={-10.0} end={63.4} decimals={1} enableScrollSpy={true} /> */}
              </div>
              <div className="title">Billion AED Off-Plan Sales Value</div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="counter-block">
              <div className="number">
                <CountUp start={0} end={25971} enableScrollSpy={true} scrollSpyDelay={100} separator="," suffix=" +">
                  {({ countUpRef }) => <span ref={countUpRef} />}
                </CountUp>
                {/* <CountUp start={0} end={25971} decimal="," suffix="+" enableScrollSpy={true} /> */}
              </div>
              <div className="title">Apartments</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
