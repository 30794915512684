import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

// components
import Header from "../../components/shared/Header/Header";
import Carousel from "../../components/core/Home/Carousel";
import SectionHeader from "../../components/shared/SectionHeader/SectionHeader";
import PropertyList from "../../components/shared/PropertyList/PropertyList";
import LocationList from "../../components/shared/LocationList/LocationList";
import RealEstateReports from "../../components/core/Home/RealEstateReports";
import ReviewList from "../../components/shared/ReviewList/ReviewList";
import PostList from "../../components/shared/PostList/PostList";
import Partners from "../../components/core/Home/Partners";
import Footer from "../../components/shared/Footer/Footer";

import { blogLists, buyingProperty, featuredProperty, neighborhood, userReview } from "../../data/home";
import Videos from "../../components/core/Home/Videos";
import { BASE_URL, requestApi } from "../../services/api.config";

export default function Home() {
  const [homeData, setHomeData] = useState([]);
  const [developData, setDevelopData] = useState([]);
  const reviewSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplaySpeed: 10000,
    autoplay: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const fetchHomeData = async () => {
    const homeReq = {
      url: `${BASE_URL}/page/home`,
      method: "GET",
      data: "",
    };
    const resData_home = await requestApi(homeReq);
    if (resData_home.status === true) {
      setHomeData(resData_home?.results?.dubaiProperty);
      setDevelopData(resData_home?.results?.developers);
    } else {
      //  console.log('Fetching Developer List')
    }
  };
  useEffect(() => {
    fetchHomeData();
  }, []);
  return (
    <>
      {/* Header */}
      <Header />

      <div className="home__page">
        {/* Carousel Slider */}
        <Carousel />

        {/* About */}
        <section className="about px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h4>ABOUT US</h4>
                <h1>Best Places to Visit</h1>
                <p>
                  Welcome to KR, where we redefine the real estate experience with a commitment to excellence, integrity, and innovation. As a leading player in the real estate industry, we take pride
                  in delivering unparalleled services to our clients.
                </p>
                <p>
                  At KR, our mission is to provide exceptional real estate solutions that exceed our clients' expectations. We aim to build lasting relationships by offering transparency, personalized
                  service, and expert guidance throughout the entire real estate journey.
                </p>
                <p>To be the preferred and trusted real estate partner, known for our unwavering dedication to professionalism, ethical practices, and delivering optimal value to our clients..</p>
                <p className="text-center mt-5">
                  <Link className="btn btn-outline-dark">Read More</Link>
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Featured Property */}
        <section className="py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <SectionHeader title="PREMIER PROPERTIES IN DUBAI" subTitle="Best Properties in Dubai" />
              </div>
            </div>
            <div className="row">
              {homeData.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <PropertyList data={item} type="property" />
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Real Estate Reports */}
        <RealEstateReports />

        {/* Find Your Neighborhood */}
        <section className="py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <SectionHeader title="Explore Your Neighborhood" subTitle="Apartments Villas  Townhouses" />
              </div>
            </div>
            <div className="row">
              {developData.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <LocationList data={item} key={index} />
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Buying Property in Dubai */}
        <section className="py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <SectionHeader title="View Dubai Location" subTitle="Apartments Villas  Townhouses" />
              </div>
            </div>
            <div className="row">
              {buyingProperty.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <PropertyList data={item} type="location" />
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Customer Review */}
        <section className="customer-reviews py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <SectionHeader title="CUSTOMER REVIEWS" subTitle="Best Properties in Dubai" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Slider {...reviewSettings}>
                  {userReview.map((item, index) => (
                    <div key={index}>
                      <ReviewList data={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </section>

        {/* Latest Blogs */}
        <section className="py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <SectionHeader title="LATEST BLOGS" subTitle="Exercitation ullamco laboris nisiut aliquip irure" />
              </div>
            </div>
            <div className="row">
              {blogLists.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <PostList data={item} />
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Videos */}
        {/*   <Videos /> */}

        {/* Partners */}
        <Partners />
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
