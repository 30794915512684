import React from "react";

import ProjectBanner from "../../components/shared/Banner/ProjectBanner";
import Footer from "../../components/shared/Footer/Footer";
import Header from "../../components/shared/Header/Header";

import { FaHandshake } from "react-icons/fa";
import { BiPurchaseTagAlt } from "react-icons/bi";
import { MdCrisisAlert } from "react-icons/md";

export default function About() {
  return (
    <>
      {/* Header */}
      <Header />

      {/* Project Banner */}
      <ProjectBanner />

      <div className="about__page">
        {/* About Welcome */}
        <section className="py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="about-images">
                  <img src="https://www.arkrealestatedubai.com/wp-content/uploads/2024/02/riyas-mohammed-2quacwOKlJ8-unsplash-scaled.jpg" className="img-fluid" alt="" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-content">
                  <h1>
                    <span>Welcome to</span> Karimi Real Estate!
                  </h1>
                  <h2>A Premier Dubai Real Estate Firm for Those Appreciating Time, Finances, and the Latest Insights​</h2>
                  <p>
                  Karimi Real Estate is a trusted name in the real estate industry, renowned for its commitment to excellence and client satisfaction. With a rich legacy of providing top-notch property services, we specialize in facilitating seamless real estate transactions in Dubai. Our team of experienced professionals is dedicated to understanding your unique needs, offering personalized guidance, and ensuring a smooth process from start to finish. We pride ourselves on delivering value, transparency, and up-to-date market insights. Whether you're buying, selling, or investing, Karimi Real Estate is your reliable partner, ensuring your real estate journey is efficient, informed, and successful.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Mission & Vision */}
        <section className="mission-section px-3" style={{ backgroundImage: `url("../images/map-shape-3.png")` }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <div className="mission-vision">
                  <h2>
                    <strong>Mission</strong>
                  </h2>
                  <p>
                  Aiming to stand as the foremost real estate firm in the UAE, we dedicate ourselves to offering unparalleled service and proficiency to our clients, guiding them towards realizing their property ambitions and optimizing their returns.
                  </p>
                  <h5 className="mt-4">
                    <strong>Core Value</strong>
                  </h5>
                  <ul className="d-flex flex-wrap">
                    <li>Integrity</li>
                    <li>Excellence</li>
                    <li>Teamwork</li>
                    <li>Transparency</li>
                    <li>Innovation</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mission-vision">
                  <h2>
                    <strong>Vision</strong>
                  </h2>
                  <ul>
                    <li>Discovering and Procuring Premier Off-Plan and Resale Investment Opportunities in Dubai</li>
                    <li>Offering Expert Insights and Strategies for Maximizing Investment Returns to Our Clients</li>
                    <li>Securing Optimal Prices and Favorable Terms through Expert Negotiation for Our Clients</li>
                    <li>Overseeing the Full Investment Journey from Initiation to Completion, Allowing Our Clients to Concentrate on Their Priorities</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Consulting */}
        <section className="py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <div className="features-items">
                  <div className="icon" style={{ backgroundColor: "#1ab69d" }}>
                    <FaHandshake />
                  </div>
                  <h2>
                    <strong>CONSULTING</strong>
                  </h2>
                  <ul>
                    <li>Real Estate Sales in Dubai, UAE</li>
                    <li>Residential and Commercial Property</li>
                  
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="features-items">
                  <div className="icon" style={{ backgroundColor: "#EE4A62" }}>
                    <BiPurchaseTagAlt />
                  </div>
                  <h2>
                    <strong>PURCHASE SUPPORT</strong>
                  </h2>
                  <ul>
                    <li>Property Search and Selection</li>
                    <li>Financial Consultation</li>
                    <li>Negotiation Services</li>
                    <li>Providing insights into current market trends and property values.</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4">
                <div className="features-items">
                  <div className="icon" style={{ backgroundColor: "#4664E4" }}>
                    <MdCrisisAlert />
                  </div>
                  <h2>
                    <strong>AFTER-SALES SERVICE</strong>
                  </h2>
                  <ul>
                    <li>Recommendations for property maintenance and upkeep</li>
                    <li>Assistance in case of future resale, including market analysis and pricing.</li>
                    <li>Periodic follow-ups to ensure satisfaction with the property and our services</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-enquiry px-3" style={{ backgroundImage: `url("../images/enquiry-bg.jpg")` }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h2>
                  <strong>Inquire About Our Latest Projects</strong>
                </h2>
                <p>Stay at the forefront of luxury and innovation by inquiring about our latest real estate projects. </p>

                <div className="contact-now">
                  <p>Call Us Via</p>
                  <h5>+971544101973</h5>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="about-people px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h2>
                  <strong>Our Commitments to Our  People</strong>
                </h2>
                <p> At the core of our ethos are the promises we make to our people.</p>
                <p>
                We pledge unwavering support for their professional growth, fostering an environment that values collaboration, innovation, and personal development. Our commitment extends to providing a workplace that prioritizes inclusivity, respect, and a healthy work-life balance. We promise to celebrate achievements, nurture talent, and create a thriving community where every individual's contribution is recognized and valued. Together, we build not just careers, but a shared journey towards success and fulfillment..
                </p>

                <h5>Therefore, we promise you three things:</h5>

                <ul>
                  <li>
                    We will always provide you with the most powerful and user-friendly technology available anywhere, in order to allow you to learn your markets faster and to stay up-to- date more
                    easily.
                  </li>
                  <li>We will create payment structures that pay you more than any comparable real estate companies, as a means of reinforcing our commitment to absolute integrity.</li>
                  <li>As our reputation for intelligence, integrity, and proven performance grows, everyone will share in our company’s prosperity, not just a select few.</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
