import React from "react";
import { IoIosStar } from "react-icons/io";
import "./style.scss";

export default function ReviewList(props) {
  const { data } = props;

  return (
    <div className="customer-review">
      <div className="user">
        <div className="avtar" style={{ backgroundImage: `url(${data.image})` }}></div>
        <p className="user-name">{data.name}</p>
        <div className="rating">
          <IoIosStar />
          <IoIosStar />
          <IoIosStar />
          <IoIosStar />
          <IoIosStar />
        </div>
      </div>
      <div className="content">
        <p>{data.content}</p>

        <p className="read-more">Read More</p>
      </div>
    </div>
  );
}
