import React from "react";
import Slider from "react-slick";
import SectionHeader from "../../shared/SectionHeader/SectionHeader";

export default function Partners() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 3,
    autoplaySpeed: 5000,
    autoplay: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <section className="py-5 px-3 partners">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <SectionHeader title="Our Partners" subTitle="" />
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-md-12 text-center">
            <Slider {...settings}>
              <div className="partner-logo">
                <img src="images/partner/AlBarariDubai.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/alsharq.png" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/azizi.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/binghatti.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/DAMAC.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/danube_properties_in_dubai.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/deyaar-dubai.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/dubai-properties.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/ellington.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/emaar.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/forum_original.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/ifa.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/liv_black.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/mag_original.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/majid_Al_Futtaim.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/meraas.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/nakheel.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/NSHAMA.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/omniyat.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/palma_holding_black.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/reportage_properties_b.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/select-group.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/SevenTides.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/sobha.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/tebyan_white.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/tiger_group.svg" className="img-fluid" alt="" />
              </div>
              <div className="partner-logo">
                <img src="images/partner/wasl_properties_black.svg" className="img-fluid" alt="" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
}
