import React from "react";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import { CiCalendar } from "react-icons/ci";
import "./style.scss";

export default function PostList(props) {
  const { data } = props;

  return (
    <div className="post-list">
      <Link to="/">
        <div className="thumb-image" style={{ backgroundImage: `url(${data.image})` }}></div>
      </Link>
      <div className="content">
        <h3 className="title">
          <Link to="/">{data.title}</Link>
        </h3>
        <p>{data.content}</p>
        <div className="blog-bottom">
          <div className="d-flex align-items-center">
            <CiCalendar size="20" />
            <span className="ms-1">{data.date}</span>
          </div>
          <Link to="/" className="d-flex align-items-center">
            <span className="me-2">Read More</span>
            <FaArrowRightLong />
          </Link>
        </div>
      </div>
    </div>
  );
}
