import React, { useState } from "react";
import Header from "../../components/shared/Header/Header";
import ProjectBanner from "../../components/shared/Banner/ProjectBanner";
import Footer from "../../components/shared/Footer/Footer";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope, FaGlobe } from "react-icons/fa6";
import { BASE_URL, requestApi } from "../../services/api.config";
import Swal from "sweetalert2";

export default function Contact() {
  const [formData, setFormData] = useState({});
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const successAlert = ()=>{
    Swal.fire({
      title:"Thank You !!!",
      text:"Your inquiry completed successfully",
      icon:"success",
      allowOutsideClick:false,
      confirmButtonText: "OK"
    }).then((result)=>{
      if(result.value){
        window.location.reload();
      }else{

      }
    })
  }

  const errorAlert = ()=>{
    Swal.fire({
      title:"Oops!!!",
      text:"Something went wrong",
      icon:"error",
      allowOutsideClick:false,
      confirmButtonText: "OK"
    }).then((result)=>{
      if(result.value){
        window.location.reload();
      }else{

      }
    })
  }


  
  const inquiryFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const reqData = {
        url: `${BASE_URL}/inquiry`,
        method: "POST",
        data: {
          userName: formData.name,
          email: formData.email,
          country: formData.country,
          phone: formData.phone,
          message: formData.comment,
        },
      };
      const resData = await requestApi(reqData);
      setFormData({})
      if(resData.status == true){
        // alert("Thankyou for inquiry..")
        // window.location.href = "/";
        successAlert();
      }else{
        errorAlert()
      }
    } catch (error) {
      console.log("---- error---", error);
      errorAlert()
    }
  };

  return (
    <>
      {/* Header */}
      <Header />

      {/* Project Banner */}
      <ProjectBanner />

      <div className="contact__page">
        <section className="py-5 px-3">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="get-in-touch">
                  <h2>Get in Touch</h2>

                  <div className="single-contact">
                    <p className="title">Location</p>
                    <div className="d-flex align-items-center">
                      <div className="icon me-2">
                        <FaMapMarkerAlt />
                      </div>
                      <div className="content">19th floor , Office No-31, The Binary Tower , Business Bay, Dubai, UAE</div>
                    </div>
                  </div>

                  <div className="single-contact">
                    <p className="title">Email</p>
                    <div className="d-flex align-items-center">
                      <div className="icon me-2">
                        <FaEnvelope />
                      </div>
                      <div className="content">info@karimi.ae</div>
                    </div>
                  </div>

                  <div className="single-contact">
                    <p className="title">Phone</p>
                    <div className="d-flex align-items-center">
                      <div className="icon me-2">
                        <FaPhoneAlt />
                      </div>
                      <div className="content">Karimi Real Estate: +971544101973</div>
                    </div>
                  </div>

                  <div className="single-contact">
                    <p className="title">Website</p>
                    <div className="d-flex align-items-center">
                      <div className="icon me-2">
                        <FaGlobe />
                      </div>
                      <div className="content">www.karimi.ae</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="inquiry-now">
                  <h2>Inquiry Now</h2>

                  <form onSubmit={(e) => inquiryFormSubmit(e)}>
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input type="text" className="form-control shadow-none" placeholder="Your Name..."  name="name"
                        onChange={(e) => handleChange(e)} />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Email</label>
                      <input type="email" className="form-control shadow-none" placeholder="Email Us..."   name="email"
                        onChange={(e) => handleChange(e)} />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Country</label>
                      <select className="form-select shadow-none"  name="country"
                        onChange={(e) => handleChange(e)}>
                        <option>Select Country</option>
                        <option value="India">India</option>
                        <option value="USA">USA</option>
                        <option value="Dubai">Dubai</option>
                        <option value="Japan">Japan</option>
                      </select>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Phone</label>
                      <input type="text" className="form-control shadow-none" placeholder="Phone Number..." name="phone"
                        onChange={(e) => handleChange(e)}/>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Comments</label>
                      <textarea className="form-control shadow-none" rows="5" placeholder="Write Comment..."  name="comment"
                        onChange={(e) => handleChange(e)}></textarea>
                    </div>

                    <button type="submit" className="btn btn-primary">
                      Submit Now
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="google-map">
            <div className="map-responsive">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d115534.80898158063!2d55.183625966765504!3d25.187611380142673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f691f3e775c45%3A0xce9960f75a33192e!2sKarimi%20Real%20Estate%20LLC!5e0!3m2!1sen!2sin!4v1708165267775!5m2!1sen!2sin"
                width={600}
                height={450}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
