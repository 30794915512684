import React from "react";
import Header from "../../components/shared/Header/Header";
import ProjectBanner from "../../components/shared/Banner/ProjectBanner";
import Footer from "../../components/shared/Footer/Footer";
import { blogLists } from "../../data/home";
import PostList from "../../components/shared/PostList/PostList";

export default function Blog() {
  return (
    <>
      {/* Header */}
      <Header />

      {/* Project Banner */}
      <ProjectBanner />

      <div className="blog__page">
        <section className="py-5 px-3">
          <div className="container">
            <div className="row">
              {blogLists.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <PostList data={item} />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
