import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import "./style.scss";

export default function PropertyList(props) {
  const { data, type } = props;

  if (type === "location") {
    return (
      <div className="property-list">
        <Link to="/property/the-sapphire-on-sheikh-zayed-road-dubai">
          <div className="thumb-image" style={{ backgroundImage: `url(${data.image})` }}></div>
        </Link>
      </div>
    );
  }

  if (type === "property") {
    return (
      <div className="property-list">
        <Link to={`/property/${data._id}`}>
          <div className="thumb-image" style={{ backgroundImage: `url(${data.displayImage})` }}></div>
        </Link>
        <div className="content">
          <div className="category">
            <Link to="/">{data.developer.userName}</Link>
          </div>
          <h3 className="title">
            <Link to="/">{data.propertyName}</Link>
          </h3>
          <p className="location">
            <FaMapMarkerAlt />
            <span className="ms-1">{data.location}</span>
          </p>
        </div>
      </div>
    );
  }
}
