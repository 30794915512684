import React, { useEffect, useState } from "react";

import { developers } from "../../data/project";
import Header from "../../components/shared/Header/Header";
import ProjectBanner from "../../components/shared/Banner/ProjectBanner";
import Footer from "../../components/shared/Footer/Footer";
import DeveloperList from "../../components/shared/DeveloperList/DeveloperList";
import { useParams } from "react-router";
import { BASE_URL, requestApi } from "../../services/api.config";

import Pagination from "react-js-pagination";

export default function Developers(props) {
  const [developDetails, setDevelopDetails] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const { slug } = useParams();

  const fetchParticularDeveloperDetails = async () => {
    const DeveloperDetailsReq = {
      url: `${BASE_URL}/properties?developer=${slug}&page=1&limit=10`,
      method: "GET",
      data: "",
    };
    const resData_developer = await requestApi(DeveloperDetailsReq);

    if (resData_developer.status) {
      setDevelopDetails(resData_developer?.results);
    } else {
      alert(resData_developer.message);
    }
  };

  useEffect(() => {
    fetchParticularDeveloperDetails();
  }, [slug]);

  console.log(developDetails);

  return (
    <>
      {/* Header */}
      <Header />

      {/* Project Banner */}
      <ProjectBanner />

      <div className="developers__page">
        {/* Developers */}
        <section className="py-5 px-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                {developDetails?.data?.length > 0 && (
                  <div className="top-header">
                    <h3>
                      Showing 1-{developDetails?.data?.length} of {developDetails?.pagination?.totalItems} results
                    </h3>
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              {developDetails?.data?.length > 0 &&
                developDetails?.data?.map((item, index) => (
                  <div className="col-md-4" key={index}>
                    <DeveloperList data={item} />
                  </div>
                ))}

              {developDetails?.data?.length == 0 && (
                <div className="col-md-12 py-4 text-center">
                  <p>No Developer Found!</p>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-12 py-2 text-center">
                <div style={{ margin: "0 auto", display: "inline-block" }}>
                  <Pagination activePage={activePage} itemsCountPerPage={10} totalItemsCount={450} pageRangeDisplayed={5} onChange={(page) => console.log(page)} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Footer */}
      <Footer />
    </>
  );
}
