import React from "react";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiOutlineRectangleStack } from "react-icons/hi2";
import { IoBedOutline } from "react-icons/io5";

export default function DeveloperList({ data }) {
  return (
    <div className="developer-list">
      <Link to={`/property/${data._id}`}>
        <div className="thumb-image" style={{ backgroundImage: `url(${data.displayImage})` }}></div>
      </Link>
      <div className="content">
        <div className="category">
          <Link to={`/property/${data._id}`}>{data.type}</Link>
        </div>
        <h3 className="title">
          <Link to={`/property/${data._id}`}>{data.propertyName}</Link>
        </h3>
        <p className="location">
          <FaMapMarkerAlt />
          <span className="ms-1">{data.location}</span>
        </p>

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <span className="feature-icon">
              <IoBedOutline size="17" />
            </span>
            <span className="featute-text">4 Beds</span>
          </div>

          <div className="d-flex align-items-center">
            <span className="feature-icon">
              <HiOutlineRectangleStack size="17" />
            </span>
            <span className="featute-text">3200 Sqft</span>
          </div>
        </div>
      </div>

      <div className="bottom-content">
        <div className="d-flex align-items-center">
          <div className="image me-2">
            <img src={process.env.PUBLIC_URL + "/images/k.png?s=40&amp;d=mm&amp;r=g"} height="10" width="10" alt="Author" />
          </div>
          <div className="name">KR Real Estate</div>
        </div>
        <Link to={`/property/${data._id}`} className="btn btn-dark btn-sm">
          Details
        </Link>
      </div>
    </div>
  );
}
