import React from "react";
import Slider from "react-slick";
import SearchProperty from "./SearchProperty";

export default function Carousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplaySpeed: 5000,
    autoplay: true,
    pauseOnHover: false,
  };

  return (
    <section className="carousel">
      <div className="heading">
        <h2>INVEST. BUY. LIVE .</h2>
        <p>Unlock your Dream Home: KR - your Ultimate Destination in the UAE!</p>
      </div>
      <Slider {...settings}>
        <div>
          <img src="../images/2.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="../images/3.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="../images/4.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="../images/5.jpg" className="img-fluid" />
        </div>
        <div>
          <img src="../images/6.jpg" className="img-fluid" />
        </div>
      </Slider>

      <div className="overlay"></div>

      <SearchProperty />
    </section>
  );
}
